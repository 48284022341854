export const environment = {
  name: 'eagles',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://qa.heroes.mims.rewaatech.com',
  webSocketUrl:
    'wss://z61mfw06l3.execute-api.us-east-1.amazonaws.com/qaheroes/',
  fullViewClientId: '',
  sallaAppClientId: '73bf6f8b43257c2f25256e98dc001bc5',
  zidAppClientId: '',
  bonatAppClientId: '',
  bonatRedirectUrl: '',
  cognitoAuthUrl: '',
  userPoolId: 'us-east-1_QC1BP9hkE',
  clientId: '6jr2d0t5eanvd494ja8tp3tons',
  bonatServiceToken: '',
  s3InventoryBucket:
    'https://s3.amazonaws.com/qa-heroes-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/qa.heroes.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  enableUserGuiding: true,
  enableUserPilot: false,
  launchDarklyClientSideId: '63566e81f1d7751181713670',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  onboardingApiPath: '/api/onboarding',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl: 'https://reporting.qa.heroes.rewaatech.com/cubejs-api/v1',
  imexServiceFunctionUrl:
  'https://pq5uz4eibwj3kkwcthzix7tm5q0tyghx.lambda-url.ap-south-1.on.aws/imex/export',
  appVersion: require('../../package.json').version,
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
  imexUrl: 'http://localhost:8005/imex',
  dashboardApiUrl: '',
  rewaaHrUrl: 'https://hr.rewaatech.com/account/login?action=CognitoSignIn',
};
